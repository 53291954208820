export const mainHeader = 'Realizacje';

export const alternativeTexts = [
  'Baner Będzin',
  'Baner Przeźmierowo',
  'Banery reklamowe Wrocław',
  'Baner reklama Brodnica',
  'Baner reklamowy Gdynia',
  'Baner Michałowice',
  'Baner Pruszków',
  'Baner reklamowy Bydgoszcz',
  'Baner reklama Darłowo',
  'Baner reklamowy Izabelin',
  'Baner reklama Izabelin',
  'Baner reklamowy Brodnica',
  'Baner reklama Brodnica',
  'Baner Brodnica',
  'Baner reklamowy Kamień Pomorski',
  'Baner reklama Kamień Pomorski',
  'Baner Łódź',
  'Baner reklamowy Darłowo',
  'Baner Darłowo',
  'Baner reklama Darłowo',
  'Baner reklamowy Kraków',
  'Baner reklamowy Koszalin',
  'Baner reklamowy Łódź',
  'Baner reklama Łódź',
  'Baner reklamowy Kędzierzyn Koźle',
  'Siatka mesh Szczecin',
  'Siatka mesh Szczecin',
  'Baner reklamowy Kiełczów',
  'Baner reklama Poniec',
  'Baner reklamowy Źródła',
  'Baner reklamowy Poniec',
  'Baner reklama Długołęka',
  'Baner reklamowy Gorzów Wielkopolski',
  'Baner Stargard',
  'Baner Gdynia',
  'Baner Nowa Wieś',
  'Baner reklamowy Bełżyce',
  'Baner reklama Grodków',
  'Baner reklama Sierzchów',
  'Baner reklamowy Łódź',
  'Baner reklamowy Przeworno',
  'Banery reklamowe Białystok',
  'Blockout Łódź',
  'Baner Gródek nad Dunajcem',
];
