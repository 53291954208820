/* eslint-disable react/prop-types */
import * as React from 'react';
import { graphql } from 'gatsby';
import Gallery from '@browniebroke/gatsby-image-gallery';
import * as messages from '../messages/realizacje.messages';
import { Container, Heading, SEO } from '../components/components';

const Realizacje = ({ data }) => {
  const images = data.allFile.edges.map(({ node }, index) => ({
    ...node.childImageSharp,
    thumbAlt: `${messages.alternativeTexts[index]}`,
  }));

  return (
    <>
      <SEO title={messages.mainHeader} />
      <Container subPage>
        <Heading headingLevel="h1" title={messages.mainHeader} centered />
        <Gallery images={images} gutter="1rem" colWidth="50" mdColWidth="25" />
      </Container>
    </>
  );
};

export const query = graphql`
  {
    allFile(filter: {relativeDirectory: {eq: "gallery"}}, sort: { fields: name }) {
      edges {
        node {
          name
          relativePath
          relativeDirectory
          childImageSharp {
            thumb: gatsbyImageData(width: 300, height: 300, placeholder: BLURRED)
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }`;

export default Realizacje;
